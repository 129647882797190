/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';
import './styles/icons.scss';
import './styles/style.scss';
import 'datatables/media/css/jquery.dataTables.min.css';

// start the Stimulus application
import './bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'popper.js/dist/popper.min';
import 'waves/dist/waves.min';
import './js/jquery.slimscroll';
import 'datatables/media/js/jquery.dataTables.min';
import './js/app';
